import { addOn, format, styles, defaultStyles } from "./pdf.style";
import htmlToPdfmake from "html-to-pdfmake";
import FormatDate from "moment";
import axios from "axios";

export async function PDFCompiler(user, mail, timeline, comments = [], files = [], useSigantories = true) {

	const imageData = await axios.get(
		`${process.env.REACT_APP_API_URI}/company-logo-print?v=${Math.floor(Math.random() * 100)}`
	).then(({ response }) => response);

	const marginY = {
		columns: addOn.marginY(5),
	};

	const marginY10 = {
		columns: addOn.marginY(10),
	};

	const pageBreak = {
		columns: addOn.pageBreak(),
	};

	const top = {
		style: "forms",
		layout: "noBorders",
		table: {
			widths: ["11.11%", "88.888%"],
			body: [
				[{ text: "Date:" }, { text: FormatDate(mail.target_date).format("MMMM DD, YYYY"), bold: true }],
				[{ text: "To:" }, { text: mail.mail_to, bold: true }],
				[{ text: "From:" }, { text: mail.mail_from, bold: true }],
				[{ text: "Subject:" }, { text: mail.subject, bold: true }],
			],
		},
	};

	const letter = {
		stack: htmlToPdfmake(mail.letter, {
			defaultStyles: defaultStyles
		})
	};

	const { formatted, signature_remarks } = useSigantories ? format(timeline) : {
		formatted: timeline,
		signature_remarks: null,
	};

	const file = files.length <= 0 ? { text: " " } : {
		style: "forms",
		layout: "noBorders",
		table: {
			headerRows: 1,
			widths: ["*"],
			body: [
				[{ text: "Attachments:", style: "signatoryTitle" }],
				[{ ol: files.map(({ name }) => ({ text: name })) }]
			],
		},
	};

	const comment = comments.length <= 0 ? { text: " " } : {
		style: "forms",
		layout: "noBorders",
		table: {
			headerRows: 1,
			widths: ["*"],
			body: [
				[{ text: "Comments:", style: "signatoryTitle" }],
				...comments.map((row) => [{
					stack: [
						{
							text: `${row.first_name} ${typeof row.middle_name === 'string' ? `${row.middle_name?.substring(0, 1)}.` : ''} ${row.last_name} ${row?.suffix_name || ""}`,
							fontSize: 10,
						},
						{
							text: row.remarks,
							margin: [10, 5],
							fontSize: 10,
						},
						{
							text: FormatDate(row.created_at).format("MMMM DD, YYYY @ LT").replace("@", "at"),
							alignment: "right",
							fontSize: 10,
							margin: [10, 5],
						},
						{ canvas: [{ type: "line", x1: 515, y1: 0, x2: 0, y2: 0, lineWidth: 1 }] },
					],
					margin: [15, 0, 0, 0],
				}])
			],
		},
	};

	let content = [top, marginY, letter];

	if (useSigantories) {
		if (files.length > 0) {
			content[3] = marginY10;
			content[4] = file;
		}
		if (signature_remarks) {
			content[5] = marginY10;
			content[6] = signature_remarks;
		}
		if (comments.length > 0) {
			content[7] = marginY10;
			content[8] = comment;
		}
		content[9] = pageBreak;
		content[10] = formatted;
	}

	return {
		pageMargins: [40, 70, 40, 70],
		info: {
			title: mail.subject,
			author: `${mail.first_name} ${mail.middle_name} ${mail.last_name}`,
			creator: `${user.name.first} ${user.name.middle} ${user.name.last}`,
			subject: mail.subject,
		},
		defaultStyle: {
			font: "Lato",
		},
		header: [{
			image: "logo",
			alignment: "right",
			margin: [0, 30, 18, 0],
			fit: [90, 40],
		}],
		content: content,
		footer: {
			stack: [
				{ canvas: [{ type: "line", x1: 40, y1: 0, x2: 560, y2: 0, lineHeight: 2, lineWidth: 1, color: "green" }] },
				{ canvas: [{ type: "line", x1: 40, y1: 0, x2: 560, y2: 0, lineHeight: 2, lineWidth: 1, color: "green" }] },
				{ text: process.env.REACT_APP_COMPANY_TITLE, style: "footer" },
				{ text: process.env.REACT_APP_COMPANY_ADDRESS, style: "footer" },
				{ text: process.env.REACT_APP_COMPANY_NUMBER, style: "footer" },
			],
		},
		images: {
			logo: imageData,
		},
		styles: styles
	};
}
