import { useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import Ajax from "../../../Utility/Ajax";
import { enhanceUrlToObject, LoadingPost, NoPost } from "./utils/utils";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as validate from "yup";
import InfiniteScroll from "react-infinite-scroll-component";
import TopPosts from "./Top/TopPosts";
import TopChannels from "./Top/TopChannels";
import PostFormatDashboard from "./Post/PostFormatDashboard";
import TopButtonsDashboard from "./utils/TopButtonsDashboard";

const validation = validate
	.object({
		channel_code: validate.string().required(),
		user_code: validate.string().min(3).required(),
		filter_status: validate.string().min(1).required(),
		post_priority: validate.string().required(),
		selectedPage: validate.string().min(1).required(),
		limit: validate.string().min(1).required(),
	})
	.required();

const postsDefault = {
	pagination: {
		next: "http://test/?page=1",
	},
	rows: [],
};

export default function Dashboard({ user, forum, onSelect }) {
	const [isSubmitting, setSubmitting] = useState(false);
	const [posts, setPosts] = useState(postsDefault);

	const { setValue, watch } = useForm({
		resolver: yupResolver(validation),
		defaultValues: {
			limit: 5,
			selectedPage: 1,
			user_code: "ALL",
			channel_code: "ALL",
			filter_status: "Newest",
			post_priority: "ALL",
		},
	});

	const onPostLoad = (data = []) => {
		Ajax.post(
			"/forum-v2/post/with-filter",
			{
				limit: 5,
				selectedPage: data.selectedPage,
				current_user_code: user.code,
				user_code: "ALL",
				channel_code: "ALL",
				filter_status: data.filter_status,
				post_priority: data.post_priority,
			},
			() => {
				if (!Boolean(data.more)) setPosts(postsDefault);
				setSubmitting(true);
			},
			(result) => {
				switch (result.response) {
					case "resource-found": {
						setPosts((p) => ({
							rows:
								p.rows.length > 0
									? p.rows.map((parent, i, pst) => {
											result.rows.map((child) => {
												const index = pst.findIndex(
													(finder) => String(finder.id) === String(child.id)
												);
												if (index < 0) {
													pst.push(child);
												} else {
													pst[index] = child;
												}
												return child;
											});
											return parent;
									  })
									: result.rows,
							pagination: result.pagination,
						}));
						break;
					}
					default: {
						setPosts({
							rows: [],
							pagination: {
								next: null,
							},
						});
						break;
					}
				}
				setSubmitting(false);
			},
			() => {
				setPosts({
					rows: [],
					pagination: {
						next: null,
					},
				});
				setSubmitting(false);
			}
		);
	};

	const filter_status = watch("filter_status");
	const post_priority = watch("post_priority");

	useEffect(() => {
		onPostLoad({
			selectedPage: 1,
			filter_status: filter_status,
			post_priority: post_priority,
			more: false,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter_status, post_priority]);

	return (
		<Container className="px-0">
			<div className="row">
				<div className="col-12 col-md-8 order-md-1 order-2">
					<Row className="px-3">
						<Col
							xs={12}
							className="sticky-top bg-white border border-1 border-gray-300 mb-5"
							style={{ zIndex: "10" }}
						>
							<Form className="d-flex justify-content-between py-3">
								<TopButtonsDashboard
									forum={forum}
									onSelect={onSelect}
									isSubmitting={isSubmitting}
								/>
								<div className="col-6 col-md-5 px-1 align-self-center">
									<InputGroup size="sm">
										<InputGroup.Text>Order By</InputGroup.Text>
										<Form.Select
											disabled={isSubmitting}
											size="sm"
											onChange={(e) =>
												setValue("filter_status", e.target.value)
											}
											value={watch("filter_status")}
										>
											<option>NEWEST</option>
											<option>OLDEST</option>
											<option>MOST COMMENTED</option>
											<option>MOST VIEWED</option>
											<option>MOST VOTED</option>
										</Form.Select>
									</InputGroup>
								</div>
							</Form>
						</Col>
						<InfiniteScroll
							className="row px-0"
							next={() =>
								onPostLoad({
									selectedPage: enhanceUrlToObject(posts.pagination.next),
									filter_status: watch("filter_status"),
									post_priority: watch("post_priority"),
									more: true,
								})
							}
							dataLength={posts.rows.length}
							hasMore={typeof posts.pagination.next === "string"}
							loader={LoadingPost()}
							endMessage={
								posts.rows.length > 0 ? (
									<div className="text-center">Yay! You have seen it all</div>
								) : (
									NoPost("No posts yet")
								)
							}
						>
							{posts.rows.map((post) => (
								<PostFormatDashboard
									user={user}
									post={post}
									key={post.id}
									setStatus={(newStatus) =>
										setPosts((value) => ({
											...value,
											rows: value.rows.map((row) => {
												return row.post_code === post.post_code
													? {
															...row,
															post_status: newStatus,
													  }
													: row;
											}),
										}))
									}
									forum={forum}
									onSelect={onSelect}
								/>
							))}
						</InfiniteScroll>
					</Row>
				</div>
				<div className="col-12 col-md-4 order-md-2 order-1 px-3">
					<div className="row">
						<TopChannels user={user} forum={forum} onSelect={onSelect} />
						<TopPosts
							channel="ALL"
							user="ALL"
							forum={forum}
							onSelect={onSelect}
						/>
					</div>
				</div>
			</div>
		</Container>
	);
}
