import { Button, Col, Container, Form, Image, OverlayTrigger, Popover } from "react-bootstrap";
import CreateDate from "moment";
import React from "react";
import { userProfilePath } from "../../../../Utility/Plugins";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as validate from "yup";
import { EncodeSignatures, statusColor, validSignatories } from "../other/helpers";
import Alert from "../../../../Utility/sweet-alert";
import { TimelineService } from "../../services/timelines.service";

const signatureValidation = validate.object({
	remarks: validate.string().max(2000).label("Comment"),
	created_by: validate.string().required().label("Commentator"),
	token: validate.string().required().label("Token"),
}).required();

function WithUseCases({ workApproval, approver, reloadRequest }) {

	const signatureForm = useForm({
		resolver: yupResolver(signatureValidation),
		defaultValues: {
			id: workApproval.id,
			token: workApproval.token,
			approver: approver.code,
			remarks: ''
		}
	});

	return (
		<SignatureContainer
			workApproval={workApproval}
			approver={approver}
			form={signatureForm}
			reloadRequest={reloadRequest}
		/>
	);
}

class SignatureContainer extends React.Component { 
	constructor() {
		super();
		this.swal = new Alert();

		this.state = {
			signatories: []
		};
	}

	componentDidMount() {
		this.setState({
			signatories: EncodeSignatures(
				this.props.workApproval.approvers,
				(value, _) => value,
				validSignatories
			).signatureInputs
		})
	}
	
	render() {
		return (
			<Col xs={12} className="pb-5">
				<Container fluid className="px-0">
					{
						this.state.signatories.map((signatory, signatoryKey) => (
							<div className="pt-10" key={signatoryKey}>
								<b>{signatory.title}: </b>
								<div className="row">
									{
										signatory.approvers.map((signature, signatureKey) => (
											<div key={signatureKey} className="col-12 col-md-4">
												<div className="row py-2 g-1" key={signatureKey}>
													<OverlayTrigger
														placement="auto"
														trigger={["click", "focus", "hover"]}
														overlay={
															<Popover className="w-250px">
																<Popover.Header className="text-center">
																	{signature.department_name}
																</Popover.Header>
																<Popover.Body>
																	<div className="d-flex justify-content-center">
																		<Image
																			rounded
																			width="200px"
																			src={userProfilePath(signature.to_user_code)}
																			onError={
																				image => (image.target.src = "/assets/media/avatars/blank.png")
																			}
																		/>
																	</div>
																</Popover.Body>
															</Popover>
														}
													>
														<div className="py-2 border-bottom border-dark fs-5 cursor-pointer text-uppercase">
															{signature.first_name} {signature.middle_name && `${signature.middle_name?.substring(0, 1)}. `} {signature.last_name} {signature.suffix_name}
														</div>
													</OverlayTrigger>
													<div className="d-flex flex-column justify-content-between px-0">
														<div className="fs-6 text-uppercase">{signature.position_name}</div>
														{
															signature?.status_at && (
																["APPROVED", "CLOSED", "CANCELED", "REJECTED", "RECEIVED"].includes(signature.status) ? (
																	<div>
																		<b className="text-gray-500 fs-7 text-uppercase fw-normal">
																			{signature.status} LAST {
																				CreateDate(signature.status_at).format(
																					"MMMM DD, YYYY - LT"
																				)
																			}
																		</b>
																	</div>
																) : (
																	<div>
																		{
																			Number(signature.mail_viewed) === 1 && (
																				<b className="text-gray-500 fs-7 text-uppercase fw-normal">
																					SEEN LAST {
																						CreateDate(signature.updated_at).format(
																							"MMMM DD, YYYY - LT"
																						)
																					}
																				</b>
																			)
																		}
																	</div>
																)
															)
														}
														<div className="d-flex justify-content-end px-0">
															<div className="px-0">
																<span className={`text-${statusColor(['REJECTED', 'CANCELED'].includes(this.props.workApproval.status) && signature.status === 'PENDING' ? 'CLOSED' : signature.status)} fs-6 px-0`}>
																	{
																		['REJECTED', 'CANCELED'].includes(this.props.workApproval.status) && signature.status === 'PENDING' ? 'CLOSED' : signature.status
																	}
																</span>
															</div>
														</div>
														{
															Number(signature.itsMyTurnToSign) === 1 && (
																["ON PROCESS"].includes(this.props.workApproval.status) && String(this.props.approver.code) === String(signature.to_user_code) && (
																	<div className="no-print mt-2">
																		<Form noValidate>
																			<Form.Control
																				{...this.props.form.register("remarks")}
																				as="textarea"
																				placeholder="Remarks"
																				className="col-12 mb-2"
																				readOnly={this.state.busy}
																			/>
																			<Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
																				{this.props.form.formState.errors.remarks?.message}
																			</Form.Control.Feedback>
																			<div className="col-12 d-flex justify-content-end">
																				<Button
																					size="sm"
																					variant="primary"
																					className="me-2"
																					disabled={this.state.busy}
																					onClick={
																						() => {
																							this.onProcess(
																								signature,
																								this.props.form.getValues("remarks"),
																								"APPROVED"
																							);
																						}
																					}
																				>
																					Approve
																				</Button>
																				<Button
																					size="sm"
																					variant="danger"
																					disabled={this.state.busy}
																					onClick={
																						() => {
																							this.onProcess(
																								signature,
																								this.props.form.getValues("remarks"),
																								"REJECTED"
																							);
																						}
																					}
																				>
																					Reject
																				</Button>
																			</div>
																		</Form>
																	</div>
																)
															)
														}
													</div>
												</div>
											</div>
										))
									}
								</div>
							</div>
						))
					}
				</Container>
			</Col>
		);
	}

	onProcess(approver, remarks, approvalStatus) {
		if (remarks || approvalStatus === 'APPROVED') {

			this.props.form.clearErrors();

			this.swal.confirm({
				html: approvalStatus === "REJECTED" ? 'Reject this request?' : 'Approve this request?',
				confirmButtonText: approvalStatus === "REJECTED" ? 'Reject' : 'Approve',
				customClass: {
					confirmButton: approvalStatus === "REJECTED" ? 'btn btn-sm btn-danger my-1' : 'btn btn-sm btn-primary my-1'
				},
				preConfirm: async () => {
					return await TimelineService.put({
						id: approver.id,
						token: this.props.workApproval.token,
						remarks: remarks,
						status: approvalStatus
					}).then(({ message }) => {
						this.swal.success(message).then(() => {
							this.props.reloadRequest()
						});
					});
				}
			});

		} else {
			this.props.form.setError('remarks', {
				message: `Remarks is required, when rejecting a request.`
			});
		}
	}
} 

export default WithUseCases