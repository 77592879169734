import React from "react";
import ReactDOM from "react-dom";
import Authenticate from "./Authentication";
import Success from "./Signin/Success";
import ImageResize from "quill-image-resize-module-react";

import { Quill } from "react-quill";
import { getNotificationPermission } from "./Utility/NotificationHelper";
import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { AdministrationWrapper } from "./System/Administrator/AdministrationWrapper";
import { WorkApprovalWrapper } from "./System/WorkApproval/WorkApprovalWrapper";
import { ForumV2Wrapper } from "./System/ForumV2/ForumV2Wrapper";
import { UserWrapper } from "./System/User/UserWrapper";
import { GoogleFormWrapper } from "./System/GoogleForm/GoogleFormWrapper";
import SelectSystemWrappper from "./SelectSystemWrappper";

import PrintPreview from "./System/WorkApproval/pages/PrintPreview";

import LoadingImage from "./Component/Quill/ImageUploader/blots";

import './Utility/axios';
import base64url from "base64url";
import Ajax from "./Utility/Ajax";

require("dotenv").config();

const readAndUploadFile = async (file, quill, quillRange, options) => {
	if (file) {
		const reader = new FileReader();
		reader.onload = async () => {
			const base64ImageSrc = reader.result;

			quill.focus();
			quillRange = await quill.getSelection();

			setTimeout(async () => {
				try {
					quill.insertEmbed(
						quillRange.index,
						LoadingImage.blotName,
						`${base64ImageSrc}`,
						"user"
					);
				} catch (error) {
					quill.focus();
					quillRange = await quill.getSelection();
				}
			}, 500);

			quill.focus();
			quillRange = await quill.getSelection();

			setTimeout(async () => {
				try {
					quillRange = await options.upload(file, quill, quillRange);
				} catch (error) {
					quill.focus();
					quillRange = await quill.getSelection();
				}
			}, 500);
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	}

	return await quillRange;
};

Quill.register(
	"modules/imageUploaderWorkApproval",
	function (quill, options) {

		let quillRange = quill.getSelection();
		if (options?.disabled) {
			quill.root.addEventListener(
				"paste",
				async (evt) => {
					let clipboard = evt.clipboardData || window.clipboardData;

					// IE 11 is .files other browsers are .items
					if (clipboard && (clipboard.items || clipboard.files)) {
						let items = clipboard.items || clipboard.files;
						const IMAGE_MIME_REGEX = /^image\/(jpe?g|gif|png|svg|webp)$/i;

						for (let i = 0; i < items.length; i++) {
							if (IMAGE_MIME_REGEX.test(items[i].type)) {
								let file = items[i].getAsFile ? items[i].getAsFile() : items[i];
								if (file) {
									evt.preventDefault();
									quill.focus();
									quillRange = await quill.getSelection();
									quill.deleteText(quillRange.index, 3, "user");
								}
							}
						}
					}
				},
				false
			);
		}
	}
);

Quill.register("modules/imageUploader", function (quill, options) {
	if (typeof options?.upload === "function") {
		const toolbar = quill.getModule("toolbar");
		quill.focus();
		let quillRange = quill.getSelection();
		let fileHolder = document.getElementById("react-quill-image-upload");
		if (!fileHolder) {
			fileHolder = document.createElement("input");
			fileHolder.setAttribute("id", "react-quill-image-upload");
			fileHolder.setAttribute("type", "file");
			fileHolder.setAttribute("accept", "image/*");
			fileHolder.setAttribute("style", "visibility:hidden");
			fileHolder.onchange = async function (evt) {
				const file = evt.target.files[0];
				quill.focus();
				quillRange = await quill.getSelection();
				setTimeout(async () => {
					quillRange = await readAndUploadFile(
						file,
						quill,
						quillRange,
						options
					);
				}, 500);
			};
			document.body.appendChild(fileHolder);
		}

		quill.root.addEventListener(
			"paste",
			async (evt) => {
				let clipboard = evt.clipboardData || window.clipboardData;

				// IE 11 is .files other browsers are .items
				if (clipboard && (clipboard.items || clipboard.files)) {
					let items = clipboard.items || clipboard.files;
					const IMAGE_MIME_REGEX = /^image\/(jpe?g|gif|png|svg|webp|jpg)$/i;

					for (let i = 0; i < items.length; i++) {
						if (IMAGE_MIME_REGEX.test(items[i].type)) {
							let file = items[i].getAsFile ? items[i].getAsFile() : items[i];
							if (file) {
								evt.preventDefault();
								quill.focus();
								quillRange = await quill.getSelection();
								quillRange = await readAndUploadFile(
									file,
									quill,
									quillRange,
									options
								);
							}
						}
					}
				}
			},
			false
		);

		toolbar.addHandler("image", async function () {
			quill.focus();
			quillRange = await quill.getSelection();
			fileHolder.click();
		});
	}

	// function getImgUrls(delta) {
	//   return delta.ops.filter(i => i.insert && i.insert.image).map(i => i.insert.image);
	// }

	// quill.on("text-change", function (delta, oldContents) {
	//   const inserted = getImgUrls(delta);
	//   const deleted = getImgUrls(quill.getContents().diff(oldContents));
	//   inserted.length && console.log('insert', inserted)
	//   deleted.length && console.log('delete', deleted)
	// })
});

Quill.register("modules/imageResize", ImageResize);

Quill.register("modules/counter", function (quill, options) {
	const container = document.querySelector(options.container);
	quill.on("text-change", function () {
		const text = quill.getText();
		if (quill.getLength() >= options.limit) {
			quill.deleteText(options.limit, quill.getLength());
			container.innerText = `${quill.getLength() - 1} / ${options.limit} characters"`;
		} else {
			if (options.unit === "word") {
				container.innerText = `${text.split(/\s+/).length} words"`;
			} else {
				container.innerText = `${quill.getLength() - 1} / ${options.limit} characters"`;
			}
		}
	});
});

function RootWrapper() {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<RootWrapperClass
			navigate={navigate}
			location={location}
		/>
	)
}

class RootWrapperClass extends React.Component {

	constructor() {
		super();

		this.state = {
			fetchingUser: true,
			isAccessing: true,
			user: null,
			system: null,
			path: null
		}
	}

	componentDidMount() {
		this.setState(() => {
			const hasUserStorage = localStorage.getItem(base64url.encode('user'));
			if (hasUserStorage) {

				const hasSystemStorage = localStorage.getItem(base64url.encode("system"));
				if (hasSystemStorage) {
					return {
						fetchingUser: false,
						isAccessing: false,
						user: JSON.parse(hasUserStorage),
						system: JSON.parse(hasSystemStorage),
						path: this.props.location.pathname.split('/')
					};
				} else {
					return {
						fetchingUser: false,
						isAccessing: false,
						user: JSON.parse(hasUserStorage),
						system: null,
						path: this.props.location.pathname.split('/')
					};
				}
			}

			return {
				fetchingUser: false,
				isAccessing: false,
				user: null,
				system: null,
				path: this.props.location.pathname.split('/')
			};
		}, () => {
			if (this.state.user) {
				getNotificationPermission();

				if (this.state.system && !this.state.path.includes('print-preview') && !this.state.path.includes('user')) {
					this.onAccessSytem(this.state.system);
				}
			}
		});
	}

	render() {

		if (this.state.fetchingUser || this.state.isAccessing) {
			return <></>
		}

		if (this.state.user) {
			return (
				<Routes>
					<Route exact path="system/administration/*" element={<AdministrationWrapper />} />
					<Route exact path="system/work-approval/*" element={<WorkApprovalWrapper />} />
					<Route exact path="system/work-approval/print-preview/:token" element={<PrintPreview />} />
					<Route exact path="system/google-form/*" element={<GoogleFormWrapper />} />
					<Route exact path="system/forum-v2/*" element={<ForumV2Wrapper />} />
					<Route exact path="system/user/*" element={<UserWrapper />} />
					<Route exact path="*" element={<SelectSystemWrappper />} index />
				</Routes>
			);
		}

		return (
			<Routes>
				{
					/* {Boolean(process.env.REACT_APP_SWAP_SEARCH_USER_TO_REGISTRATION) ? (
						<Route exact path="/search-user" index element={<SearchUser />} />
					) : (
						<Route exact path="/register" index element={<Registration />} />
					)} */
				}
				<Route exact path="system/oauth/success" element={<Success />} />
				<Route
					exact
					path="*"
					element={
						<Authenticate
							setUser={
								user => {
									this.setState({
										user: user
									});
								}
							}
						/>
					}
				/>
			</Routes>
		);
	}

	onAccessSytem(system) {
		console.log(system)
		this.setState({
			system: system.code,
			isAccessing: true
		}, () => {
			const path = system.href.split('/');
			if (path.length >= 2 && !this.state.path.includes(path[1])) {
				Ajax.post(
					"/administrator/system-page-user/access", {
					user_code: this.state.user.code,
					system_code: system.code,
				},
					() => {
					},
					({ rows }) => {
						this.setState({
							isAccessing: false
						}, () => {
							localStorage.setItem(base64url.encode("system"), JSON.stringify(system));
							localStorage.setItem(base64url.encode("pages"), JSON.stringify(rows));
							this.props.navigate(`system${system.href}`);
						});
					},
					() => {
						this.setState({
							isAccessing: false
						})
					}
				);
			} else {
				this.setState({
					isAccessing: false
				})
			}
		})
	}
}


ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<RootWrapper />
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);
