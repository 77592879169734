import FormatDate from "moment";

export const addOn = {
	margin: (l = 0, t = 0, r = 0, b = 0) => ["", { text: " ", margin: [l, t, r, b] }, ""],
	marginX: (l = 0, r = 0) => ["", { text: " ", margin: [l, 0, r, 0] }, ""],
	marginY: (t = 0, b = 0) => ["", { text: " ", margin: [0, t, 0, b] }, ""],
	pageBreak: (pageBreak = "after") => ["", { text: " ", pageBreak: pageBreak }, ""],
	signatoryTitle: (title = "Prepared By") => [{ text: `${title}:`, style: "signatoryTitle" }, "", ""],
	signatureData: (name, position, status, pageBreak = "") => [
		{ text: name.toUpperCase(), style: "signatoryUserName" },
		{ canvas: [{ type: "line", x1: 170, y1: 0, x2: 0, y2: 0, lineWidth: 1 }] },
		{ text: position, style: "signatoryUserPosition" },
		{
			text: status,
			pageBreak: pageBreak,
			style: ["APPROVED", "RECEIVED"].includes(status)
				? "signatoryUserStatusApproved"
				: status === "REJECTED"
				? "signatoryUserStatusRejected"
				: "signatoryUserStatusPending",
		},
	],
};

export const format = (mail) => {
	let divide = 0,
		divideChild = 0;
	const remarkeds = [];

	return {
		formatted: mail.signatureInputs.map((parent, i) => {
			const length = parent.approvers.length;
			const float = Math.round(parseFloat(Number(length) / 3 + 0.33333));
			divide = parseInt(length <= 3 ? 1 : float);
			divideChild += divide;

			let fixer = 0,
				minus = divide,
				child = [],
				pusher = [];
			
			parent.approvers.forEach((low, ic) => {
				if (fixer === 0) pusher = child[minus - divide] = [];
				const user = `${low.first_name} ${typeof low.middle_name === 'string' ? `${low.middle_name?.substring(0, 1) }.` : ''} ${low.last_name} ${low?.suffix_name || ""}`;
				if (divideChild >= 10 && ic === 5) {
					pusher.push(
						addOn.signatureData(
							user.toString().toUpperCase(),
							low.position_name.toString().toUpperCase(),
							low.status,
							"after"
						)
					);
					divideChild = 0;
				} else {
					pusher.push(
						addOn.signatureData(
							user.toString().toUpperCase(),
							low.position_name.toString().toUpperCase(),
							low.status
						)
					);
				}
				if (fixer === 2) {
					fixer = 0;
					minus++;
				} else fixer++;

				if (low?.remarks && low?.status_at) {
					remarkeds.push(low);
				}
			});

			const additionalList = child.map((row) => [row[0], row[1] || "", row[2] || ""]);
			return {
				style: "signatories",
				layout: "noBorders",
				table: {
					headerRows: 1,
					widths: ["33.333%", "33.333%", "33.333%"],
					body: [addOn.signatoryTitle(parent.title), ...additionalList],
				},
			};
		}),
		signature_remarks:
			remarkeds.length > 0
				? {
						style: "forms",
						layout: "noBorders",
						table: {
							headerRows: 1,
							widths: ["*"],
							body: [
								[{ text: "Remarks:", style: "signatoryTitle" }],
								...remarkeds.map((row) => [
									{
										stack: [
											{
												text: `${row.first_name} ${row.middle_name.substring(0, 1)}. ${row.last_name} ${row?.suffix_name || ""}`,
												fontSize: 10,
											},
											{
												text: row.remarks,
												margin: [10, 5],
												fontSize: 10,
											},
											{
												text: FormatDate(row.status_at).format("MMMM DD, YYYY @ LT").replace("@", "at"),
												alignment: "right",
												fontSize: 10,
												margin: [10, 5],
											},
											{
												canvas: [
													{
														type: "line",
														x1: 515,
														y1: 0,
														x2: 0,
														y2: 0,
														lineWidth: 1,
													},
												],
											},
										],
										margin: [15, 0, 0, 0],
									},
								]),
							],
						},
				  }
				: null,
	};
};

export const defaultStyles = {
	p: "",
	ol: {
		margin: [25, 0, 0, 0],
	},
	li: "",
	ul: {
		margin: [25, 0, 0, 0],
	},
	h1: {
		fontSize: 18,
	},
	h2: {
		fontSize: 16,
	},
	h3: {
		fontSize: 14,
	},
	h4: {
		fontSize: 12,
	},
	h5: {
		fontSize: 10,
	},
	h6: {
		fontSize: 8,
	},
};

export const styles = {
	"ql-indent-1": {
		margin: [30, 0, 0, 0],
	},
	"ql-indent-2": {
		margin: [55, 0, 0, 0],
	},
	"ql-indent-3": {
		margin: [80, 0, 0, 0],
	},
	"ql-indent-4": {
		margin: [105, 0, 0, 0],
	},
	"ql-indent-5": {
		margin: [130, 0, 0, 0],
	},
	"ql-indent-6": {
		margin: [155, 0, 0, 0],
	},
	"ql-indent-7": {
		margin: [180, 0, 0, 0],
	},
	"ql-indent-8": {
		margin: [205, 0, 0, 0],
	},
	"ql-align-center": {
		alignment: "center",
	},
	"ql-align-justify": {
		alignment: "justify",
	},
	"ql-align-right": {
		alignment: "right",
	},
	forms: {
		fontSize: 12,
		fontFace: "Lato",
	},
	signatories: {
		fontSize: 12,
		fontFace: "Lato",
	},
	header: {
		margin: [40, 0, 0, 0],
	},
	footer: {
		fontSize: 9,
		margin: [40, 0, 0, 0],
	},
	signatoryTitle: {
		margin: [0, 10, 0, 10],
		bold: true,
	},
	signatoryUserName: {
		bold: false,
		fontSize: 10,
		margin: [0, 0, 0, 2],
	},
	signatoryUserPosition: {
		fontSize: 10,
		margin: [0, 2, 0, 2],
		bold: true,
	},
	signatoryUserStatusPending: {
		fontSize: 10,
		alignment: "right",
		color: "orange",
		bold: true,
	},
	signatoryUserStatusApproved: {
		fontSize: 10,
		alignment: "right",
		color: "green",
		bold: true,
	},
	signatoryUserStatusRejected: {
		fontSize: 10,
		alignment: "right",
		color: "red",
		bold: true,
	},
};
