import jQuery from "jquery";
import {serialize} from "object-to-formdata";
import base64url from "base64url";
import { Alert } from "./Plugins";

export default class Ajax {
    static env = {
        WORK_APPROVAL: {
            UPLOAD: {
                MAX_TOTAL_SIZE: process.env.REACT_APP_WORK_APPROVAL_MAX_UPLOAD_TOTAL_SIZE
            }
        },
        FORUM: {
            UPLOAD: {
                MAX_TOTAL_SIZE: process.env.REACT_APP_FORUM_MAX_UPLOAD_TOTAL_SIZE
            }
        },
        JWT_SECRET : process.env.REACT_APP_JWT_SECRET,
        API_URI : process.env.REACT_APP_API_URI,
        SSO_CLIENT_ID: process.env.REACT_APP_SSO_CLIENT_ID,
        SSO_AUTH_ENDPOINT: process.env.REACT_APP_SSO_AUTH_ENDPOINT,
        SSO_REDIRECT_URI:  process.env.REACT_APP_SSO_REDIRECT_URI,    
        SOCKET_ENDPOINT: process.env.REACT_APP_SOCKET_END_POINT
    }

    static getTokenFromStorage ($token = "") {
        $token = localStorage.getItem(base64url.encode("token"));
        return $token;
    }
    
    static setup(url, data, beforeCallback, successCallback, failureCallback, isXHR = false, type = "POST")
    {
        const setting = {
            url: `${this.env.API_URI}${url}`,
            type: type,
            data: serialize(data),
            headers: {
                "Authorization" : `Bearer ${this.getTokenFromStorage()}`
            },
            dataType: "JSON",
            processData: false,
            contentType: false,
        };

        if(isXHR)
        {
            setting.xhr = () => {
                const xhr = jQuery.ajaxSettings.xhr(); 
                xhr.upload.onprogress = progress => beforeCallback(progress);
                return xhr;
            }
        }
        else
        {
            setting.beforeSend = result => beforeCallback(result);
        }
        
        
        setting.success = result => successCallback(result.messages);
        setting.error = result => {
            if (result?.responseJSON && result?.responseJSON) {
                if (result.status === 401) {
                    Alert(
                        "Session Expired",
                        "Your session has expired, please login again",
                        "error",
                        () => {
                            localStorage.clear();
			                window.location.href = "/";
                        }
                    );
                }
            }

            return failureCallback(result);
        }

        // jQuery.ajaxSettings.xhr().withCredentials = false;
        
        return jQuery.ajax(setting);
    }

    static post(url, data, beforeCallback, successCallback, failureCallback, isXHR = false) {
        return this.setup(url, data, beforeCallback, successCallback, failureCallback, isXHR, "POST");
    }

    static get(url, data, beforeCallback, successCallback, failureCallback, isXHR = false)
    {
        return this.setup(url, data, beforeCallback, successCallback, failureCallback, isXHR, "GET");
    }

    static put(url, data, beforeCallback, successCallback, failureCallback, isXHR = false) 
    {
        return this.setup(url, data, beforeCallback, successCallback, failureCallback, isXHR, "PUT");
    }
}