import jwt from "jsonwebtoken";
import base64url from "base64url";
import Ajax from "./Ajax";

export const getNotificationTokenFromStorage = async () => new Promise((resolve, reject) => {
    const subscription = sessionStorage.getItem("subscription");
    if (subscription) {
        resolve(JSON.parse(subscription))
    } else {
        reject(null)
    }
})

export const generateSocketToken = (user) => jwt.sign({ user }, base64url.encode(Ajax.env.JWT_SECRET));

function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4);
    // eslint-disable-next-line no-useless-escape
    const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}


const subscribePushManager = async (registered = null) => {
    if (registered) {
        console.log("Push manager subscribing");
        const subscription = await registered.pushManager.subscribe({
            userVisibleOnly: true,
            //public vapid key
            applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_WEBPUSH_PUBLIC_KEY)
        });

        if (subscription) {
            console.log("Push manager subscribed");
            sessionStorage.setItem("subscription", JSON.stringify(subscription));
        } else {
            console.log("Push manager not subscribed");
        }
    } else {
        console.error("Push Manager not existing");
    }
}

export const registerServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(async services => {
            if (services.length === 0) {
                try {
                    const service = await navigator.serviceWorker.register('/worker.js', { scope: '/' });
                    if (service.installing) {
                        console.log("Service worker installing");
                    } else if (service.waiting) {
                        console.log("Service worker installed");
                    } else if (service.active) {
                        console.log("Service worker active");
                        subscribePushManager(service);
                    }
                } catch (error) {
                    console.error(`Registration failed`);
                }
            } else {
                const service = services[0];
                if (service) {
                    const subscription = await service.pushManager.getSubscription();
                    if (subscription) {
                        sessionStorage.setItem("subscription", JSON.stringify(subscription.toJSON()));
                    } else {
                        subscribePushManager(service);
                    }
                }
            }
        });
    } else {
        console.error(`Service worker not supported `);
    }
}

export const getNotificationPermission = () => new Promise((resolve) => {
    if (("Notification" in window)) {
        if (Notification.permission === "granted") {
            registerServiceWorker().catch(() => {
                resolve(false);
            });
            resolve(true);
        } else if (Notification.permission === "denied") {
            resolve(false);
        } else {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    registerServiceWorker().catch(() => {
                        resolve(false);
                    });
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        }
    } else {
        console.log("This browser does not support desktop notification");
        resolve(false);
    }
})