import { Card, Col, Container, Row } from "react-bootstrap";
import ParseStringToReactHtml from "html-react-parser";

export default function MailContainer({ workApproval }) {
	return (
		<Col xs={12} className="pb-5">
			<Container fluid className="px-0">
				<Card className="border-2 border-gray-300 rounded-0">
					<Card.Header className="border-2 border-gray-300 py-5">
						<Card.Title className="col-12">
							<Row className="col-12">
								<Col xs={12} className="pb-2">
									<Row>
										<Col xs={3}>
											Date
										</Col>
										<Col xs={9}>
											: {workApproval.target_date}
										</Col>
									</Row>
								</Col>
								<Col xs={12} className="pb-2">
									<Row>
										<Col xs={3}>
											To
										</Col>
										<Col xs={9}>
											: {workApproval.mail_to}
										</Col>
									</Row>
								</Col>
								<Col xs={12} className="pb-2">
									<Row>
										<Col xs={3}>
											From
										</Col>
										<Col xs={9}>
											: {workApproval.mail_from}
										</Col>
									</Row>
								</Col>
								<Col xs={12} className="pb-2">
									<Row>
										<Col xs={3}>
											Subject
										</Col>
										<Col xs={9}>
											: {workApproval.subject}
										</Col>
									</Row>
								</Col>
							</Row>
						</Card.Title>
					</Card.Header>
					<Card.Body className="ql-editor">
						{
							workApproval.letter && ParseStringToReactHtml(
								workApproval.letter
							)
						}
					</Card.Body>
				</Card>
			</Container>
		</Col>
	);
}
