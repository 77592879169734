import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Button, Card, Col, Container, Form, Image, InputGroup, Spinner, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as validate from "yup";
import CreateDate from "moment";
import { TableState, userProfilePath } from "../../../../Utility/Plugins";
import { CommentService } from "../../services/comment.service";

const commentValidation = validate.object({
	remarks: validate.string().min(1).max(2000).required().label("Comment"),
	created_by: validate.string().required().label("Commentator"),
	token: validate.string().required().label("Token"),
}).required();

function WithUseCases({ workApproval, approver, setComments }) {

	const commentForm = useForm({
		resolver: yupResolver(commentValidation),
		defaultValues: {
			created_by: approver.code,
			token: workApproval.token
		}
	});

	return (
		<CommentContainer
			workApproval={workApproval}
			form={commentForm}
			approver={approver}
			setComments={setComments}
		/>
	);
}

class CommentContainer extends React.Component {

	constructor() {
		super();

		this.state = {
			busy: false,
			sending: false,
			comments: []
		}
	}

	componentDidMount() {
		this.provider();
	}

	format(commentator) {
		const commentatorName = `${commentator.first_name} ${commentator.last_name} ${commentator?.suffix_name || ''}`
		return (
			<tr key={commentator.id}>
				<td className="mt-4">
					<div className="d-flex justify-content-between">
						<div className="order-2"></div>
						{
							String(commentator.created_by) === String(this.props.approver.code) ? (
								<div className="col-11 border border-2 border-gray-300 rounded mb-5 px-3 py-2 order-3">
									<div className="d-flex justify-content-end">
										<div className="d-flex flex-column justify-content-end align-self-start px-3">
											<div className="text-end fs-7 fw-bolder text-uppercase">
												{commentatorName}
											</div>
											<div className="text-start fs-7 align-self-end">
												{
													CreateDate(commentator.created_at).format(
														"MMMM DD, YYYY - LT"
													)
												}
											</div>
										</div>
										<Image
											width="40px"
											height="40px"
											roundedCircle
											src={userProfilePath(commentator.created_by)}
											onError={(e) =>
												(e.target.src = "/assets/media/avatars/blank.png")
											}
										/>
									</div>
									<div className="text-end fs-5 py-5 px-3">
										{commentator.remarks}
									</div>
								</div>
							) : (
								<div className="col-11 border border-2 border-gray-300 rounded mb-5 px-3 py-2 order-1 bg-gray-100">
									<div className="d-flex justify-content-start">
										<Image
											width="40px"
											height="40px"
											roundedCircle
											src={userProfilePath(commentator.created_by)}
											onError={(e) =>
												(e.target.src = "/assets/media/avatars/blank.png")
											}
										/>
										<div className="d-flex flex-column justify-content-end align-self-start px-3">
											<div className="text-start fs-6 fw-bolder text-uppercase">
												{commentatorName}	
											</div>
											<div className="text-start fs-7">
												{
													CreateDate(commentator.created_at).format(
														"MMMM DD, YYYY - LT"
													)
												}
											</div>
										</div>
									</div>
									<div className="fs-5 py-5 px-3">
										{commentator.remarks}
									</div>
								</div>
							)
						}
					</div>
				</td>
			</tr>
		);
	}

	render() {
		return (
			<Col xs={12} className="pb-5">
				<Container fluid className="px-0">
					<Card className="border-2 border-gray-300 rounded-0">
						<Card.Header className="border-2 border-gray-300">
							<Card.Title>Comments: </Card.Title>
						</Card.Header>
						<Card.Body>
							<div
								id="comment-section"
								style={{
									maxHeight: "300px",
									minHeight: "300px",
									overflowY: "auto",
								}}
							>
								<Table>
									<tbody>
										{
											this.state.comments.length <= 0 || this.state.busy ? TableState(
												1,
												this.state.busy,
												this.state.busy ? 'GATHERING COMMENTS' : ' - - NO COMMENT - - '
											) : this.state.comments.map(
												(file, i) => this.format(file, i)
											)
										}
									</tbody>
								</Table>
							</div>
						</Card.Body>
						<Card.Footer className="p-3 border-2 border-gray-300">
							<Form
								noValidate
								className="col-12"
								onSubmit={
									this.props.form.handleSubmit(
										validated => this.onSubmit(validated)
									)
								}
							>
								<InputGroup>
									<Form.Control
										{...this.props.form.register("remarks")}
										as="textarea"
										placeholder="Compose a comment . . . "
										autoComplete="off"
										className="min-h-75px border-gray-300 rounded-1"
										disabled={this.state.busy || this.state.sending}
										maxLength={2000}
									/>
								</InputGroup>
								<Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
									{this.props.form.formState.errors.remarks?.message}
								</Form.Control.Feedback>
								<div className="d-flex justify-content-start">
									<Button
										size="sm"
										variant="primary"
										type="submit"
										className="mt-2"
										disabled={this.state.busy || this.state.sending}
									>
										{
											this.state.sending ? (
												<>
													<Spinner
														as="span"
														animation="border"
														size="sm"
														role="status"
														aria-hidden="true"
													/> Sending
												</>
											) : 'Send'
										}
									</Button>
								</div>
							</Form>
						</Card.Footer>
					</Card>
				</Container>
			</Col>
		);
	}

	goToBottomOfComments() {
		const commentSection = document.getElementById("comment-section");
		if (commentSection) {
			commentSection.scrollTop = commentSection.scrollTop + commentSection.scrollHeight;
		}
	};

	provider() {
		this.setState({
			busy: true,
			comments: []
		}, () => {
			CommentService.get({
				mail_code: this.props.workApproval.mail_code
			}).then((response) => {
				this.setState({
					comments: Object.keys(response.comments).map(
						comment => response.comments[comment]
					)
				}, () => {
					this.props.setComments(this.state.comments)
				});
				this.goToBottomOfComments();
			}).finally(() => {
				this.setState({
					busy: false
				});
			});
		});
	}

	onSubmit(payload) {
		this.setState({
			sending: true
		}, () => {
			CommentService.post(payload).then(() => {
				this.props.form.resetField("remarks");
				this.provider();
			}).catch(
				(oops) => {
					switch (oops?.message) {
						case "approver-not-found": {
							this.props.form.setError(
								"remarks",
								{ message: "Comment is not available if not sent to approvers" },
								{ shouldFocus: true }
							);
							break;
						}
						case "no-approvers-found": {
							this.props.form.setError(
								"remarks",
								{ message: "Comment not sent, no approvers found" },
								{ shouldFocus: true }
							);
							break;
						}
						default: {
							this.props.form.setError(
								"remarks",
								{ message: "Comment not sent" },
								{ shouldFocus: true }
							);
							break;
						}
					}
				}
			).finally(() => {
				this.setState({
					sending: false
				});
			});
		});
	}
}

export default WithUseCases