import { logOutUser } from "../../Utility/helpers";
import { userProfilePath } from "../../Utility/Plugins";
import { Dropdown, Image } from "react-bootstrap";

export default function Footer({ user }) {
    return (
        <div className="aside-user">
            <div className="aside-user d-flex flex-row-fluid align-items-center justify-content-between py-3">
                <Dropdown className="flex-row-fluid">
                    <Dropdown.Toggle className="btn btn-link d-flex flex-row-fluid align-items-center justify-content-start rounded bg-hover-primary" as="div">
                        <div className="ms-2">
                            <div className="symbol symbol-35px cursor-pointer">
                                <Image
                                    className="cursor-pointer"
                                    width={35}
                                    height={35}
                                    roundedCircle
                                    src={userProfilePath(user.code)}
                                    onError={(e) =>
                                        (e.target.src = `/assets/media/avatars/blank.png`)
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <div className="px-3 text-start text-white text-uppercase fs-8">
                                <strong>
                                    {user.name.first} {user.name.last} {user.name.suffix}
                                </strong>
                            </div>
                            <div className="px-3 text-start text-white fs-9">
                                {user.position.title}
                            </div>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="py-0 min-w-250px mt-4">
                        <Dropdown.Item className="fs-4 py-5 mb-0 bg-primary text-white border border-white" style={{ borderRadius: '10px 10px 0px 0px' }}>
                            <div className="d-flex flex-row-fluid align-items-center justify-content-center">
                                <div>
                                    <div className="symbol symbol-35px cursor-pointer">
                                        <Image
                                            className="cursor-pointer"
                                            width={35}
                                            height={35}
                                            roundedCircle
                                            src={userProfilePath(user.code)}
                                            onError={(e) =>
                                                (e.target.src = `/assets/media/avatars/blank.png`)
                                            }
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="px-3 text-start text-white text-uppercase fs-8">
                                        <strong>
                                            {user.name.first} {user.name.last} {user.name.suffix}
                                        </strong>
                                    </div>
                                    <div className="px-3 text-start text-white fs-9">
                                        {user.position.title}
                                    </div>
                                </div>
                            </div>
                        </Dropdown.Item>
                        {/* <Dropdown.Item
											className="fs-4"
											href="/system/user/change-password/"
										>
											Change Password
										</Dropdown.Item> */}
                        <Dropdown.Divider className="my-0 py-0" />
                        <Dropdown.Item className="fs-4 mb-0 py-2" href="/system/user/profile/">
                            <i className="fas fa-user w-25px ms-1"></i>Account Profile
                        </Dropdown.Item>
                        <Dropdown.Item className="fs-4 text-danger py-2" style={{ borderRadius: '0px 0px 10px 10px' }} onClick={logOutUser}>
                            <i className="fas fa-power-off w-25px ms-1 text-danger"></i>Log Out
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>

        </div>
    );
}