import { Link } from "react-router-dom";
import Breadcrumbs from "./ToolbarContent/Breadcrumbs";
import base64url from "base64url";

export default function Toolbar({ className }) {
    return (
        <div className={`toolbar ${className}`}>
            <div className="container-fluid py-6 py-lg-0 d-flex flex-column flex-sm-row align-items-lg-stretch justify-content-sm-between">
                <Breadcrumbs />
                <div className="d-flex justify-content-end align-items-lg-center overflow-auto pt-3 pt-sm-0">
                    <div className="d-flex justify-content-end">
                        <div className="d-flex align-items-center me-2">
                            <Link
                                to="/system/"
                                className="btn btn-icon btn-active-light btn-outline btn-outline-default btn-active-icon-primary"
                                data-kt-menu-trigger="click"
                                data-kt-menu-attach="parent"
                                data-kt-menu-placement="bottom-end"
                                data-kt-menu-flip="bottom"
                                onClick={() => {
                                    localStorage.removeItem(base64url.encode('system'))
                                }}
                            >
                                <i className="fs-1 fas fa-home"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}