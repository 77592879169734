import React from "react";
import { DivState, userProfilePath } from "./Utility/Plugins";

import Ajax from "./Utility/Ajax";
import base64url from "base64url";
import Breadcrumb from "./Component/Wrapper/Header/ToolbarContent/Breadcrumbs";
import { Button,  ButtonToolbar, Card, Col, Dropdown, Image, OverlayTrigger, Popover, Row, Spinner } from "react-bootstrap";
import { BreadCrumbs, BreadCrumbsProvider } from "./Component/Wrapper/Header/ToolbarContent/BreadcrumbsProvider";
import { logOutUser } from "./Utility/helpers";

import ForumDashboard from "./System/ForumV2/Page/Dashboard";
import { useNavigate } from "react-router-dom";

function ForumComponent({ user, forum, systems, selectedSystem, isAccessing, onAccess }) {

	return (
		<Row className="py-5">
			<Col xs={12} md={3} className="mb-5">
				<Card className="border border-1 border-gray-300 rounded-0">
					<Card.Header className="px-3 border-bottom border-1 border-gray-300">
						<Card.Title>
							<i className="fas fa-leaf fs-1 me-3 text-warning"></i>
							Systems
						</Card.Title>
					</Card.Header>
					<Card.Body className="p-2">
						<Row>
							{
								systems.map(
									system => (
										<Col xs={12} className="mb-2" key={system.code}>
											<Button
												size="sm"
												variant="primary-light"
												className="col-12 bg-white rounded-1 px-3 cursor-pointer bg-hover-primary text-hover-white py-3"
												disabled={isAccessing}
												onClick={
													() => {
														if (Number(system.is_redirect) === 1) {
															window.open(system.href, "system-tabs");
														} else {
															onAccess(system);
														}
													}
												}
											>
												<div className="d-flex justify-content-between">
													<div className="d-flex justify-content-start fs-3">
														<div className="align-self-center align-self-center py-3 me-3">
															<i className={`fs-1 text-primary ${system.icon}`}></i>
														</div>
														<div className="align-self-center text-start">
															{system.title}
														</div>
													</div>
													<div className="align-self-center">
														{
															Number(system.count) > 0 && (
																<span className="badge badge-primary me-3">
																	{system.count}
																</span>
															)
														}
														<span className="badge badge-link">
															{
																selectedSystem === system.code && isAccessing ? (
																	<Spinner
																		as="span"
																		animation="border"
																		size="sm"
																		role="status"
																		aria-hidden="true"
																	/>
																) : (
																	<i className="fas fa-chevron-right"></i>
																)
															}
														</span>
													</div>
												</div>
											</Button>
										</Col>
									)
								)
							}
						</Row>
					</Card.Body>
				</Card>
			</Col>
			<Col xs={12} md={9}>
				<ForumDashboard
					user={user}
					forum={forum}
					onSelect={onAccess}
				/>
			</Col>
		</Row>
	);
}

function SystemComponent({ systems, version, selectedSystem, isAccessing, onAccess }) {
	return (
		<Row className="py-5">
			{
				systems.map(
					system => (
						<Col xs={12} md={3} className="mb-6" key={system.code}>
							<Card className="border border-gray-300 border-2 rounded-1">
								<Card.Header className="border-0 min-h-5px pt-3 px-4">
									<Card.Title>
										<div></div>
									</Card.Title>
									<div className="card-toolbar">
										<strong
											className="text-primary px-3 fs-2 text-center min-h-30px"
										>
											{
												Number(system.count) > 0 && (	
													<span className="badge badge-primary">
														{system.count}
													</span>
												)
											}
										</strong>
									</div>
								</Card.Header>
								<Card.Body className="pt-5 px-5 pb-0">
									<div className="d-flex justify-content-center py-1">
										<div className="w-100 h-150px d-flex justify-content-center align-items-center rounded-1">
											<img
												alt={system.title}
												src={`${Ajax.env.API_URI}/system/${system.code}.png?v=${version}`}
												style={{
													maxHeight: '150px',
													maxWidth: '250px',
													width: 'fit-content',
													height: 'fit-content'
												}}
												onError={
													(e) => {
														e.target.src = `${process.env.REACT_APP_API_URI}/company/company-logo.png?v=${version}`;
													}
												}
											/>
										</div>
									</div>
									<div className="w-100 h-70px d-flex align-items-center">
										<i className={`fs-1 text-primary ${system.icon} p-3`}></i>
										<span className="px-3 fs-4">
											{system.title}
										</span>
									</div>
								</Card.Body>
								<Card.Footer className="pb-5 px-5 pt-2 border-0">
									<ButtonToolbar className="justify-content-between">
										<Button
											size="sm"
											variant="primary"
											className="flex-grow-1"
											disabled={isAccessing}
											onClick={
												() => {
													if (Number(system.is_redirect) === 1) {
														window.open(system.href, "system-tabs");
													} else {
														onAccess(system)
													}
												}
											}
										>
											{
												selectedSystem === system.code && isAccessing ? (
													<>
														<Spinner
															as="span"
															animation="border"
															size="sm"
															role="status"
															aria-hidden="true"
														/> Accessing
													</>
												) : 'Access'
											}
										</Button>

										<OverlayTrigger
											placement="top-end"
											trigger={["click", "focus", "hover"]}
											overlay={
												<Popover className="w-275px">
													<Popover.Header className="text-center">
														<strong>
															{system.title}
														</strong>
													</Popover.Header>
													<Popover.Body>
														{system.description}
													</Popover.Body>
												</Popover>
											}
										>
											<Button
												size="sm"
												variant="warning"
												className="ms-1 ps-5 pe-4"
											>
												<i className="fas fa-info"></i>
											</Button>
										</OverlayTrigger>
									</ButtonToolbar>
								</Card.Footer>
							</Card>
						</Col>
					)
				)
			}
		</Row>
	);
}


function WithUseCases() {

	const navigate = useNavigate();

	return (
		<SelectSystemWrappper
			navigate={navigate}	
		/>
	)
}

class SelectSystemWrappper extends React.Component {

	constructor() {
		super();
		this.state = {
			user: null,
			forum: null,
			version: Math.floor(Math.random() * 100),
			fetchingSystems: true,
			selectedSystem: null,
			systems: []
		}
	}

	componentDidMount() {
		this.setState(() => {
			const hasUserStorage = localStorage.getItem(base64url.encode('user'));
			if (hasUserStorage) {
				return {
					user: JSON.parse(hasUserStorage)
				};
			}

			return {
				user: null
			};
		}, () => {
			if (this.state.user) {
				this.getSystems();
			} else {
				logOutUser();
			}
		});
	}

	render() {
		if (this.state.user) {
			return (
				<div className="container-fluid p-10 bg-gray-100">
					<BreadCrumbsProvider>
						<Breadcrumb />
						<BreadCrumbs
							title="Select System"
							lists={[
								{ title: "Systems", href: "/system/" },
								{ title: "Home", href: "/system/" },
							]}
							beside={
								<Dropdown>
									<Dropdown.Toggle className="px-2 py-1" as="div">
										<Image
											className="cursor-pointer"
											width={50}
											height={50}
											roundedCircle
											src={userProfilePath(this.state.user.code)}
											onError={(e) =>
												(e.target.src = `/assets/media/avatars/blank.png`)
											}
										/>
									</Dropdown.Toggle>
									<Dropdown.Menu className="py-0">
										<Dropdown.Item className="fs-4 py-5 mb-0 bg-primary text-white border border-white" style={{ borderRadius: '10px 10px 0px 0px' }}>
											<div className="d-flex flex-row-fluid align-items-center justify-content-center">
												<div>
													<div className="symbol symbol-35px cursor-pointer">
														<Image
															className="cursor-pointer"
															width={35}
															height={35}
															roundedCircle
															src={userProfilePath(this.state.user.code)}
															onError={(e) =>
																(e.target.src = `/assets/media/avatars/blank.png`)
															}
														/>
													</div>
												</div>
												<div>
													<div className="px-3 text-start text-white text-uppercase fs-8">
														<strong>
															{this.state.user.name.first} {this.state.user.name.last} {this.state.user.name.suffix}
														</strong>
													</div>
													<div className="px-3 text-start text-white fs-9">
														{this.state.user.position.title}
													</div>
												</div>
											</div>
										</Dropdown.Item>
										{/* <Dropdown.Item
											className="fs-4"
											href="/system/user/change-password/"
										>
											Change Password
										</Dropdown.Item> */}
										<Dropdown.Divider className="my-0 py-0" />
										<Dropdown.Item className="fs-4 mb-0 py-2" href="/system/user/profile/">
											<i className="fas fa-user w-25px ms-1"></i>Account Profile
										</Dropdown.Item>
										<Dropdown.Item className="fs-4 text-danger py-2" style={{ borderRadius: '0px 0px 10px 10px' }} onClick={logOutUser}>
											<i className="fas fa-power-off w-25px ms-1 text-danger"></i>Log Out
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							}
						>
							Home
						</BreadCrumbs>
					</BreadCrumbsProvider>
					{
						this.state.fetchingSystems || this.state.systems.length <= 0 ? (
							DivState(
								this.state.fetchingSystems,
								this.state.fetchingSystems ? "GATHERING SYSTEMS" : " - - NO SYSTEM ACCESS - - ",
								this.state.fetchingSystems ? "warning" : "danger"
							)
						) : (
							this.state.forum ? (
								<ForumComponent
									user={this.state.user}
									forum={this.state.forum}
									systems={this.state.systems}
									isAccessing={this.state.isAccessing}
									selectedSystem={this.state.selectedSystem}
									onAccess={
										(system, callback) => this.onAccessSytem(system, callback)
									}
								/>
							) : (
								<SystemComponent
									systems={this.state.systems}
									version={this.state.version}
									isAccessing={this.state.isAccessing}
									selectedSystem={this.state.selectedSystem}
									onAccess={
										(system, callback) => this.onAccessSytem(system, callback)
									}
								/>
							)
						)
					}
				</div>
			);
		}

		return <></>;
	}

	getSystems() {
		Ajax.post(
			"/administrator/system/access", {
			user_code: this.state.user.code,
		},
			() => {
				this.setState({
					forum: null,
					fetchingSystems: true,
					systems: []
				});
			},
			({ rows }) => {
				localStorage.setItem(base64url("systems"), JSON.stringify(rows));
				this.setState({
					forum: rows.find(
						(system) => String(system.code) === String("62cd0ddab69c6")
					),
					fetchingSystems: false,
					systems: rows
				});
			},
			() => {
				this.setState({
					forum: null,
					fetchingSystems: false,
					systems: []
				});
			}
		);
	}

	onAccessSytem(system, callback) {
		this.setState({
			selectedSystem: system.code,
			isAccessing: true
		}, () => {
			Ajax.post(
				"/administrator/system-page-user/access", {
				user_code: this.state.user.code,
				system_code: system.code,
			},
				() => {
				},
				({ rows }) => {
					this.setState({
						isAccessing: false
					}, () => {
						localStorage.setItem(base64url.encode("system"), JSON.stringify(system));
						localStorage.setItem(base64url.encode("pages"), JSON.stringify(rows));
						if (typeof callback === "function") {
							callback(system, rows);
						} else {
							this.props.navigate(`system${system.href}`);
						}
					});
				},
				() => {
					this.setState({
						isAccessing: false
					})
				}
			);
		})
	}
}

export default WithUseCases;