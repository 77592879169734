import base64url from "base64url";
import React, { lazy, Suspense } from "react";
import { AsideMenu, AsideMenuProvider } from "../../Component/Aside/AsideMenuProvider";
import { Brand, BrandProvider } from "../../Component/Wrapper/Header/BrandProvider";
import { BreadCrumbs, BreadCrumbsProvider } from "../../Component/Wrapper/Header/ToolbarContent/BreadcrumbsProvider";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

import Aside from "../../Component/Aside/Aside";
import Wrapper from "../../Component/Wrapper/Wrapper";
import { logOutUser } from "../../Utility/helpers";
import { SettingService } from "./services/settings.service";
import produce from "immer";

const View = ({ children }) => (
    <Suspense fallback={<div className="d-flex justify-content-center align-items-center min-h-400px"><i className="fas fa-spinner fa-spin fs-3x"></i></div>}>
        {children}
    </Suspense>
);

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Inbox = lazy(() => import("./pages/Inbox"));
const EmployeeRequest = lazy(() => import("./pages/EmployeeRequest"));
const MyRequest = lazy(() => import("./pages/MyRequest"));
const WorkApprovalUtil = lazy(() => import("./pages/WorkApprovalUtil"));

class WorkApprovalWrapper extends React.Component {

    system = {
        title: 'Work Approval',
        icon: 'fs-4 fas fa-user-edit',
        href: '/system/work-approval'
    };

    menus = [
        {
            title: 'Dashboard',
            icon: 'fas fa-layer-group ms-1',
            href: '/work-approval/dashboard'
        },
        {
            title: 'Inbox',
            icon: 'fas fa-inbox',
            href: '/work-approval/inbox'
        },
        {
            title: 'My Requests',
            icon: 'fas fa-file-contract ms-1',
            href: '/work-approval/requests'
        },
        {
            title: 'Request Approvals',
            icon: 'fas fa-file-signature ms-1',
            href: '/work-approval/approvals'
        },
        {
            title: 'Default Filters',
            icon: 'fas fa-cogs',
            href: '/work-approval/filters'
        }
    ];

    constructor() {
        super();
        this.state = {
            user: null,
            fetchingSettings: false,
            settings: {
                inbox: {
                    display: 'table',
                    perPage: 10,
                    sortBy: 'ASC',
                    orderBy: 'timelines.status_at'
                },
                requests: {
                    display: 'table',
                    perPage: 10,
                    sortBy: 'ASC',
                    orderBy: 'mails.created_at',
                    filter_status: 'REQUESTED'
                },
                approvals: {
                    display: 'table',
                    perPage: 10,
                    sortBy: 'ASC',
                    orderBy: 'mails.created_at',
                    filter_status: 'PENDING',
                }
            }
        }
    }

    componentDidMount() {
        this.setState(() => {
            const hasUserStorage = localStorage.getItem(base64url.encode('user'));
            if (hasUserStorage) {
                return {
                    user: JSON.parse(hasUserStorage)
                };
            }

            return {
                user: null
            };
        }, () => {
            if (this.state.user) {
                this.getSettings();
            } else {
                logOutUser();
            }
        });
    }

    render() {
        if (this.state.user && !this.state.fetchingSettings) {
            return (
                <AsideMenuProvider>
                    <AsideMenu
                        lists={this.menus}
                    />
                    <BrandProvider>
                        <Brand
                            mobile={this.system.title}
                            desktop={this.system.title}
                            icon={this.system.icon}
                        />
                        <BreadCrumbsProvider>
                            <Routes>
                                <Route element={
                                    <>
                                        <Aside footer={this.state.user} />
                                        <Wrapper />
                                    </>
                                }>
                                    <Route
                                        path="dashboard"
                                        element={
                                            <View>
                                                <BreadCrumbs
                                                    lists={[
                                                        { title: this.system.title, href: this.system.href },
                                                        { title: "Dashboard", href: `#` }
                                                    ]}>
                                                    Dashboard
                                                </BreadCrumbs>
                                                <Dashboard user={this.state.user} />
                                            </View>
                                        }
                                    />
                                    <Route
                                        path="inbox"
                                        element={
                                            <View>
                                                <BreadCrumbs
                                                    lists={[
                                                        { title: this.system.title, href: this.system.href },
                                                        { title: "Inbox", href: `#` }
                                                    ]}>
                                                    Inbox
                                                </BreadCrumbs>
                                                <Inbox
                                                    user={this.state.user}
                                                    settings={this.state.settings.inbox}
                                                />
                                            </View>
                                        }
                                    />
                                    <Route
                                        path="requests/*"
                                        element={
                                            <View>
                                                <BreadCrumbs
                                                    lists={[
                                                        { title: this.system.title, href: this.system.href },
                                                        { title: "My Requests", href: `#` }
                                                    ]}>
                                                    My Requests
                                                </BreadCrumbs>
                                                <Routes>
                                                    <Route element={<Outlet />}>
                                                        <Route
                                                            index
                                                            element={
                                                                <MyRequest
                                                                    user={this.state.user}
                                                                    settings={this.state.settings.requests}
                                                                />
                                                            }
                                                        />
                                                        <Route
                                                            path=":status"
                                                            element={
                                                                <MyRequest
                                                                    user={this.state.user}
                                                                    settings={this.state.settings.requests}
                                                                />
                                                            }
                                                        />
                                                    </Route>
                                                </Routes>
                                            </View>
                                        }
                                    />
                                    <Route
                                        path="approvals/*"
                                        element={
                                            <View>
                                                <BreadCrumbs
                                                    lists={[
                                                        { title: this.system.title, href: this.system.href },
                                                        { title: "Request Approvals", href: `#` }
                                                    ]}>
                                                    Request Approvals
                                                </BreadCrumbs>
                                                <Routes>
                                                    <Route element={<Outlet />}>
                                                        <Route
                                                            index
                                                            element={
                                                                <EmployeeRequest
                                                                    user={this.state.user}
                                                                    settings={this.state.settings.approvals}
                                                                />
                                                            }
                                                        />
                                                        <Route
                                                            path=":status"
                                                            element={
                                                                <EmployeeRequest
                                                                    user={this.state.user}
                                                                    settings={this.state.settings.approvals}
                                                                />
                                                            }
                                                        />
                                                    </Route>
                                                </Routes>
                                            </View>
                                        }
                                    />
                                    <Route
                                        path="filters"
                                        element={
                                            <View>
                                                <BreadCrumbs
                                                    lists={[
                                                        { title: this.system.title, href: this.system.href },
                                                        { title: "Default Filters", href: `#` }
                                                    ]}>
                                                    Default Filters
                                                </BreadCrumbs>
                                                <WorkApprovalUtil
                                                    user={this.state.user}
                                                    settings={this.state.settings}
                                                    setSettings={
                                                        (settings) => {
                                                            this.setState({
                                                                settings: settings
                                                            })
                                                        }
                                                    }
                                                />
                                            </View>
                                        }
                                    />
                                    <Route
                                        index
                                        path="*"
                                        element={
                                            <Navigate to="dashboard" />
                                        }
                                    />
                                </Route>
                            </Routes>
                        </BreadCrumbsProvider>
                    </BrandProvider>
                </AsideMenuProvider>
            );
        }

        return <></>;
    }

    getSettings() {
        this.setState({
            fetchingSettings: true
        }, () => {
            SettingService.get({
                userCode: this.state.user.code
            }).then(({ settings }) => {
                if (settings) {
                    this.setState(prev => produce(prev, (initial) => {
                        initial.settings = {
                            inbox: {
                                ...initial.settings.inbox,
                                ...settings?.inbox
                            },
                            requests: {
                                ...initial.settings.requests,
                                ...settings?.requests
                            },
                            approvals: {
                                ...initial.settings.approvals,
                                ...settings?.approvals
                            }
                        }
                    }));
                } else {
                    SettingService.put({
                        id: 0,
                        userCode: this.state.user.code,
                        settings: this.state.settings
                    });
                }
            }).finally(() => {
                this.setState({
                    fetchingSettings: false
                });
            });
        });
    }
}

export {
    WorkApprovalWrapper
} 