import Swal from "sweetalert2"

export default class Alert {

    confirm(options = {
        title: 'Confirmation',
        html: 'Would you like to save this record?',
        confirmButtonText: 'Save',
        cancelButtonText: 'Discard',
        customClass: {
            confirmButton: 'btn btn-sm btn-success'
        },
        preConfirm: null
    }) {
        return Swal.fire({
            title: options?.title || 'Confirmation',
            html: typeof options?.html === 'function' ? options?.html() : options?.html,
            confirmButtonText: options?.confirmButtonText || 'Save',
            showCancelButton: true,
            cancelButtonText: options?.cancelButtonText || 'Discard',
            showLoaderOnConfirm: true,
            allowEscapeKey: false,
            heightAuto: false,
            backdrop: true,
            buttonsStyling: false,
            preConfirm: () => {
                if (typeof options?.preConfirm === 'function') {
                    return options?.preConfirm()
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
            customClass: {
                title: 'h5 p-3 text-warning border-bottom',
                htmlContainer: 'px-2 py-5 m-0',
                actions: 'col-12 d-flex justify-content-end p-2 border-top mx-0 my-0',
                cancelButton: 'btn btn-sm btn-outline-dark my-1 ml-1',
                confirmButton: 'btn btn-sm btn-primary my-1',
                ...options?.customClass
            },
            width: '25rem'
        })
    }

    confirmWithRemarks(options = {
        title: 'Confirmation',
        html: 'Would you like to save this record?',
        confirmButtonText: 'Save',
        cancelButtonText: 'Discard',
        customClass: {
            confirmButton: 'btn btn-sm btn-success'
        },
        preConfirm: null,
        remarksRequired: true
    }) {
        return Swal.fire({
            title: options?.title || 'Confirmation',
            html: typeof options?.html === 'function' ? options?.html() : options?.html,
            input: 'textarea',
            inputLabel: 'Remarks',
            inputAutoFocus: true,
            inputAttributes: {
                rows: 10,
                name: 'remarks',
                autocapitalize: 'off',
                placeholder: 'enter remarks here ( optional )'
            },
            confirmButtonText: options?.confirmButtonText || 'Save',
            showCancelButton: true,
            cancelButtonText: options?.cancelButtonText || 'Discard',
            showLoaderOnConfirm: true,
            allowEscapeKey: false,
            buttonsStyling: false,
            backdrop: true,
            heightAuto: false,
            preConfirm: remarks => {
                if (typeof options?.preConfirm === 'function') {
                    if (options?.remarksRequired) {
                        if (remarks) {
                            return options?.preConfirm(remarks)
                        } else {
                            // if remarks is required
                            return Swal.showValidationMessage('The remarks field is required')
                        }
                    }
                    return options?.preConfirm(remarks)
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
            customClass: {
                input: 'swal-input my-1 mx-3',
                inputLabel: 'd-flex align-self-start justify-content-start font-weight-bold col-12 px-3',
                validationMessage: 'd-flex align-self-start justify-content-start bg-white px-4 my-0 text-danger',
                title: 'h5 p-3 text-warning border-bottom',
                htmlContainer: 'px-2 py-5 m-0',
                actions: 'col-12 d-flex justify-content-end p-2 border-top mx-0 my-0',
                cancelButton: 'btn btn-sm btn-outline-dark my-1 ml-1',
                confirmButton: 'btn btn-sm btn-primary my-1',
                ...options?.customClass
            },
            width: '25rem'
        })
    }

    success(html = 'Record has been saved', title = 'Successful') {
        return Swal.fire({
            title: title,
            html: typeof html === 'function' ? html() : html,
            confirmButtonText: 'Dismiss',
            backdrop: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            buttonsStyling: false,
            heightAuto: false,
            customClass: {
                title: 'h5 p-3 text-primary border-bottom',
                htmlContainer: 'px-2 py-5 m-0',
                actions: 'col-12 d-flex justify-content-end p-2 border-top mx-0 my-0',
                confirmButton: 'btn btn-sm btn-primary my-1'
            },
            width: '25rem'
        })
    }

    loader(html = 'Record has been saved', title = 'Successful') {
        return Swal.fire({
            title: title,
            html: typeof html === 'function' ? html() : html,
            backdrop: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            buttonsStyling: false,
            heightAuto: false,
            customClass: {
                title: 'h5 p-3 text-primary border-bottom',
                htmlContainer: 'px-2 py-5 m-0',
                actions: 'col-12 d-flex justify-content-end border-top mx-0 my-0 d-none'
            },
            width: '25rem'
        })
    }

    invalid(html = '<h6>Your inputs seem to be incorrect or incomplete, please check and try again.</h6>', title = 'Validation Error') {
        return Swal.fire({
            title: title,
            html: typeof html === 'function' ? html() : html,
            confirmButtonText: 'Dismiss',
            backdrop: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            buttonsStyling: false,
            heightAuto: false,
            customClass: {
                title: 'h5 p-3 text-danger border-bottom',
                htmlContainer: 'px-2 py-5 m-0',
                actions: 'col-12 d-flex justify-content-end p-2 border-top mx-0 my-0',
                confirmButton: 'btn btn-sm btn-danger my-1'
            },
            width: '25rem'
        })
    }
}