import base64url from "base64url";
import { lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Aside from "../../Component/Aside/Aside";
import { AsideMenu, AsideMenuProvider } from "../../Component/Aside/AsideMenuProvider";
import { Brand, BrandProvider } from "../../Component/Wrapper/Header/BrandProvider";
import { BreadCrumbs, BreadCrumbsProvider } from "../../Component/Wrapper/Header/ToolbarContent/BreadcrumbsProvider";
import Wrapper from "../../Component/Wrapper/Wrapper";
import { canNavigate } from "../../Utility/helpers";

const View = ({ children }) => (
    <Suspense fallback={<></>}>
        {children}
    </Suspense>
)

export const UserWrapper = () => {

    const Profile = lazy(() => import("./Page/Profile"));
    const ChangePassword = lazy(() => import("./Page/ChangePassword"));

    const navigate = useNavigate();
    const location = useLocation();

    const [user, setUser] = useState(null);
    const [system, setSystem] = useState(null);
    const [pages, setPages] = useState(null);

    useEffect(() => {
        const userFromStorage = localStorage.getItem(base64url.encode("user"));
        if (userFromStorage) {
            const currentUser = JSON.parse(userFromStorage);

            setUser(currentUser);

            setSystem({
                title: "Account",
                href: "/user/profile/",
                icon: "fas fa-user"
            });

            setPages([
                {
                    has_child: 0,
                    href: "/user/profile/",
                    icon: "fs-4 fas fa-user",
                    system_code: "user-profile",
                    system_page_parent_code: null,
                    title: "Profile",
                    user_code: currentUser.code
                },
                // {
                //     has_child: 0,
                //     href: "/user/change-password/",
                //     icon: "fs-4 fas fa-key",
                //     system_code: "user-setting",
                //     system_page_parent_code: null,
                //     title: "Change Password",
                //     user_code: currentUser.code
                // }
            ]);
        } else {
            navigate("/");
        }
    }, [location, navigate]);

    useEffect(() => {
        if (pages && pages.length > 0 && location && !canNavigate(pages, location)) navigate("/system/");
    }, [pages, location, navigate]);

    return user && system && pages && (
        <AsideMenuProvider>
            <AsideMenu lists={pages} />
            <BrandProvider>
                <Brand mobile="Account" desktop="Account" icon="fas fa-user" />
                <BreadCrumbsProvider>
                    <Routes>
                        <Route element={
                            <>
                                <Aside footer={user} />
                                <Wrapper />
                            </>
                        }>
                            <Route path="profile" exact index
                                element={
                                    <View>
                                        <BreadCrumbs
                                            lists={[
                                                { title: system.title, href: `/system${system.href}` },
                                                { title: "Profile", href: `#` }
                                            ]}>
                                            Profile
                                        </BreadCrumbs>
                                        <Profile user={user} />
                                    </View>
                                }
                            />
                            <Route path="change-password" exact
                                element={
                                    <View>
                                        <BreadCrumbs
                                            lists={[
                                                { title: system.title, href: `/system${system.href}` },
                                                { title: "Change Password", href: `#` }
                                            ]}>
                                            Change Password
                                        </BreadCrumbs>
                                        <ChangePassword user={user} />
                                    </View>
                                }
                            />
                        </Route>
                    </Routes>
                </BreadCrumbsProvider>
            </BrandProvider>
        </AsideMenuProvider>
    );
}